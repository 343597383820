import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { categoriesArrayPropTypes, userPropTypes } from 'helpers/propTypes';

import ScrollMenu from 'react-horizontal-scroll-menu';
import Menu from 'components/main/Header/Menu';
import LogoContainer from 'containers/LogoContainer';

import './Navbar.scss';

const Navbar = (props) => {
  const {
    cartSize, categories, closeMenu, isMenuActive, isUserLoggedIn, logout, openLoginModal,
    openMenu, openSearchModal, user, show, client, subCategories
  } = props;
  const [scrollY, setScrollY] = useState(0);
  const [subCategorias, setSubCategorias] = useState([])
  const [categoryActive, setcategoryActive] = useState(null)

  const subScrollMenuRef = useRef(null);

  const handleScroll = () => setScrollY(window.scrollY);

  const cambiarSubCategorias = (categoryId) => {
    setSubCategorias(subCategories.filter((subCategory) => subCategory.category_id === categoryId))
    setcategoryActive(categories.find((category) => category.id === categoryId))
  }

  useEffect(() => {
    // Agregar un event listener cuando el componente se monta
    window.addEventListener('scroll', handleScroll);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderResponsiveMenu = () => (
    <>
      <div
        className={`fs-menu-overlay${isMenuActive ? ' pointer-active' : ''}`}
        role="presentation"
        onClick={closeMenu}
      />
      <div className={`hamburger-menu${isMenuActive ? ' active' : ''}`} style={{ top: 100 - scrollY }}>
        <div className="hamburger-menu-content">
          <Menu
            categories={categories}
            closeMenu={closeMenu}
            isMobile
            isUserLoggedIn={isUserLoggedIn}
            ulClassName="menu-top-nav"
            client={client}
          />
        </div>
      </div>
    </>
  );

  return (
    <div data-testid="navbar">
      <div className="main-nav-container" style={client && !client.design ? {backgroundColor:'white'}:{}}>
      { client && !!client.design ?
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar">
                <div className="navbar-content">
                  {isMenuActive ? (
                    <div
                      className="hamburger-container"
                      role="presentation"
                      onClick={closeMenu}
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </div>
                  ) : (
                    <div
                      className="hamburger-container"
                      role="presentation"
                      onClick={openMenu}
                    >
                      <i className="fa fa-bars" aria-hidden="true" />
                    </div>
                  )}
                  <LogoContainer />
                  <Menu
                    categories={categories}
                    closeMenu={closeMenu}
                    isUserLoggedIn={isUserLoggedIn}
                    ulClassName="navbar-menu"
                    client={client}
                  />
                  <ul className="navbar-user">
                    <li>
                      <div className="navbar-icon" role="presentation" onClick={openSearchModal}>
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </li>
                    {!user
                      ? (
                        <li>
                          <div className="navbar-icon" role="presentation" onClick={openLoginModal}>
                            <i className="fa fa-sign-in" aria-hidden="true" />
                          </div>
                        </li>
                      )
                      : (
                        <li>
                          <div className="navbar-icon" role="presentation">
                            <div className="dropdown">
                              <i className="fa fa-user" aria-hidden="true" />
                              <div className="dropdown-content">
                                <NavLink className="top-nav-item top-nav-border-right" to="/perfil">
                                  <i className="fa fa-user" aria-hidden="true" />
                                  {' '}
                                  Mi cuenta
                                </NavLink>
                                <NavLink to="/compras" className="top-nav-item top-nav-border-right" data-testid="nav-purchases">
                                  Mis compras
                                </NavLink>
                                <a href={() => false} onClick={logout}>Cerrar sesi&oacute;n</a>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}

                    <li>{show &&
                      <Link to="/carrito">
                        <div className="navbar-icon">
                          <i className="fa fa-shopping-bag" aria-hidden="true" />
                          <span id="checkout-items" className="checkout-items">
                            {cartSize}
                          </span>
                        </div>
                      </Link>}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        :
        <>
        <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar">
              <div className="navbar-content">
                <div
                  className="hamburger-container"
                  role="presentation"
                  onClick={openMenu}
                >
                  <i className="fa fa-bars" aria-hidden="true" />
                </div>
                <LogoContainer />
                <Menu
                  categories={categories}
                  subCategories={subCategories}
                  closeMenu={closeMenu}
                  isUserLoggedIn={isUserLoggedIn}
                  ulClassName="navbar-menu"
                  client={client}
                />
                <ul className="navbar-user">
                  <li>
                    <div className="navbar-icon" role="presentation" onClick={openSearchModal}>
                      <i className="fa fa-search" aria-hidden="true" />
                    </div>
                  </li>
                  {/*<li>
                    <div className="navbar-icon" role="presentation" onClick={openSearchTicketModal}>
                      <i className="fa fa-file-text-o" aria-hidden="true" />
                    </div>
</li>*/}
                  <li>
                    {show && <Link to="/carrito">
                      <div className="navbar-icon">
                        <i className="fa fa-shopping-cart" aria-hidden="true" />
                        <span id="checkout-items" className="checkout-items">
                          {cartSize}
                        </span>
                      </div>
                    </Link>
                    }
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="scorll-categories" onMouseLeave ={() => cambiarSubCategorias(0)}>
        <ScrollMenu
          alignCenter={false}
          arrowDisabledClass="scroll-menu-arrow--disabled"
          arrowLeft={<span className="arrow-prev">{' < '}</span>}
          arrowRight={<span className="arrow-next">{' > '}</span>}
          data={categories.map((category) => (
            <Link key={category.id} to={`/productos/${category.code}`} 
              onMouseEnter ={() => {
                if (subScrollMenuRef.current) {
                  subScrollMenuRef.current.state.translate = 0;
                }
                cambiarSubCategorias(category.id);
              }}>
              {category.name}
            </Link>
          ))}
          dragging={false}
          hideArrows
          hideSingleArrow
          wheel={false}
        />
        <div className="parent">
          <ScrollMenu
            ref={subScrollMenuRef}
            alignCenter={false}
            arrowDisabledClass="scroll-menu-arrow--disabled"
            arrowLeft={<span className="arrow-prev">{' < '}</span>}
            arrowRight={<span className="arrow-next">{' > '}</span>}
            data={subCategorias.map((category) => (
              <div key={category.code} className="child">
                <Link to={`/productos/${categoryActive.code}/${category.code}`} className="subcategories-navbar">
                  {category.name}
                </Link>
              </div>
            ))}
            dragging={false}
            hideArrows
            hideSingleArrow
            wheel={false}
          />
        </div>
      </div>
      </>}
      </div>
      {renderResponsiveMenu()}
    </div>
  );
};

Navbar.defaultProps = {
  user: null,
};

Navbar.propTypes = {
  cartSize: PropTypes.number.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMenuActive: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired,
  openSearchModal: PropTypes.func.isRequired,
  user: userPropTypes,
};

export default Navbar;
