import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { productPropTypes } from 'helpers/propTypes';
import QuantitySelector from 'components/commons/QuantitySelector';
import './Item.scss';

const Item = (props) => {
  const {
    colors, combinations, combinationsTalle, item, onItemClick, onQuantityChange, quantity, talles,
    getValidPriceRange, show, client,
  } = props;

  const [selectedColor, setSelectedColor] = useState(null);
  const [tallesXColor, setTallesXColor] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const handleColorSelect = (color, descriptions) => {
    setSelectedColor(color);
    setTallesXColor(descriptions);
  };

  const selectedColorImage = selectedColor
    ? (selectedColor.image_2 || selectedColor.image_1)
    : item.image1;
  const imageSource = selectedColor ? (selectedColorImage || item.image1) : item.image1;
  const hasNonNullPrice = talles.some(talle => talle.price !== null && talle?.price > 0);

  const colorsReducidos = colors.reduce((acc, obj) => {
    // Verificar si el valor de 'numero' ya está presente en el arreglo acumulador
    const exists = acc.some(
      ({ stock_description_id }) => stock_description_id === obj.stock_description_id,
    );
    // Si el valor de 'numero' no está repetido, agregamos el objeto al acumulador
    if (!exists) acc.push(obj);
    return acc;
  }, []);

  const handleMouseEnter = () => {
    let contador = -1;
    const id = setInterval(() => {
      if (contador < colorsReducidos.length && contador !== -1) {
        setSelectedColor(colorsReducidos[contador]);
        // eslint-disable-next-line no-plusplus
        contador++;
      } else {
        setSelectedColor(null);
        contador = 0;
      }
    }, 1500);
    setIntervalId(id);
  };

  const handleMouseLeave = () => {
    clearInterval(intervalId); // Detiene el intervalo
    setIntervalId(null); // Limpia el ID del intervalo
    setSelectedColor(null);
  };

  const renderProductButton = () => {
    if (item.product_wholesaler.length > 0) {
      return (
        <button type="button" onClick={onItemClick}>
          Ver Combos
        </button>
      );
    }

    if (item.stock_product_descriptions.length > 0) {
      return (
        <button type="button" onClick={onItemClick}>
          Ver detalles
        </button>
      );
    }

    if (item.stock > 0) {
      return (
        <div className={`product-quantity-selector${client && !!client.design ? '' : '-simple'}`}>
          <QuantitySelector
            limit={item.stock}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
            show={show}
          />
        </div>
      );
    }

    return <span className="product-out-of-stock">Sin stock</span>;
  };

  const renderProductButtonSimple = () => {
    if (item.product_wholesaler.length > 0) {
      return (
        <div className="product-quantity-selector-simple">
          <button type="button" onClick={onItemClick}>
            Ver Combos
          </button>
        </div>
      );
    }

    if (item.stock_product_descriptions.length > 0) {
      return (
        <div className="product-quantity-selector-simple">
          <button type="button" onClick={onItemClick}>
            Ver detalles
          </button>
        </div>
      );
    }

    if (item.stock > 0) {
      return (
        <div className="product-quantity-selector-simple">
          <QuantitySelector
            limit={item.stock}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
            show={show}
          />
        </div>
      );
    }

    return <span className="product-out-of-stock">Sin stock</span>;
  };
  
  return (
    <div className="product-item" data-testid={`product-${item.id}`}>
      { client && !!client.design ?
      <div className="product">
        <div className="product-image" onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
          <div className="product-image-icons">
            <img src={imageSource} alt={item.name} onClick={onItemClick} role="presentation" />
            {renderProductButton()}
          </div>
        </div>
        <div className="product-info">
          <h5 className="product-name" style={{ margin: 0 }} onClick={onItemClick} role="presentation">
            {window.innerWidth < 576 && item.name.length > 25 ? `${item.name.slice(0, 23)}...`: item.name}
            <div className="product-price">
              <>
                {/* eslint-disable-next-line no-nested-ternary */}
                {item.product_wholesaler.length > 0 > 0 ? (<span style={{ color: 'black' }}>Combos Disponibles!</span>) : item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <br />}
                <div className="row" style={{ justifyContent: 'space-between', margin: 0}}>
                  <span style={{ color: 'black' }}>{item ? item.categories[0].name : <></>}</span>
                  {show && `$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                </div>
              </>
            </div>
          </h5>
          {Object.values(combinations).some((array) => array.length > 0) && (
            <div className="color-selector">
              {Object.keys(combinations).map((id) => {
                const color = colors.find(
                  ({ stock_description_id }) => stock_description_id === Number(id),
                );
                const descriptions = combinations[id];
                const isMulticolor = color.stock_descriptions.name === 'Multicolor';
                return (
                  <div
                    className={`color-circle ${isMulticolor ? 'multicolor' : ''}`}
                    key={id}
                    onClick={() => handleColorSelect(color, descriptions)}
                    role="presentation"
                    style={isMulticolor ? {} : { backgroundColor: color.stock_descriptions.rgb }}
                  />
                );
              })}
            </div>
          )}
          <div className="color-selector">
            {talles.length > 0 && <>Talles:&nbsp;</>}
            {!tallesXColor ? (Object.keys(combinationsTalle)?.map((id) => {
              const talle = talles.find(
                ({ stock_description_id }) => stock_description_id === Number(id),
              );
              return (
                <span key={`talle-${talle.stock_description_id}`}>{talle.stock_descriptions.name}</span>
              );
            })) : (
              tallesXColor.map((talle) => <span key={`talle-${talle.stock_description_id}`}>{talle.stock_descriptions.name}</span>)
            )}
          </div>
        </div>
      </div>
      :<div className="product-simple">
      <div className="product-image-simple" onClick={onItemClick} role="presentation">
        <img src={item.image1} alt={item.name} />
      </div>
      <div className="product-info-simple">
        <h6 className="product-name-simple" onClick={onItemClick} role="presentation">
          {item.name}
          <div className="product-price-simple">
            {item.product_wholesaler.length > 0 > 0
              ? (
                <div className="price-item-mayorista" />
              )
              : (
                <>
                  {item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
                  <br />
                  {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                  {!!item.sale && <span data-testid="product-on-sale">{`$${item.price.toLocaleString('de-DE')}`}</span>}
                </>
              )}
          </div>
        </h6>
        {renderProductButtonSimple()}
      </div>
    </div>}
    </div>
  );
};

Item.defaultProps = {
  combinations: {},
  combinationsTalle: {},
};

Item.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  combinations: PropTypes.shape(),
  combinationsTalle: PropTypes.shape(),
  item: productPropTypes.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  talles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Item;
