import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './app.reducer';
import cartReducer from './cart.reducer';
import categoryReducer from './category.reducer';
import modalReducer from './modal.reducer';
import productReducer from './product.reducer';
import userReducer from './user.reducer';
import subCategoryReducer from './subCategory.reducer';

const reducers = combineReducers({
  form: formReducer,
  app: appReducer,
  cart: cartReducer,
  category: categoryReducer,
  subCategory: subCategoryReducer,
  modal: modalReducer,
  product: productReducer,
  user: userReducer,
});

export default reducers;
