import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

const Header = (props) => {
    const { client } = props;
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href={`${client ? client.favicon : ''}`} />
                <meta name="description" content={client ? client.description : "Descripción por defecto"} />
                <meta name="keywords" content={client ? client.key_words : ""}/>
                <link rel="apple-touch-icon" href={`${client ? client.favicon : ''}`} />

                <title>{client ? client.name_show : "Tienda"}</title>
                <meta name="description" content={client ? client.description : "Descripción por defecto"} />

                <meta itemProp="name" content={client ? client.name_show : ""} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={client ? client.name_show : ""} />
                <meta itemProp="description" content={client ? client.description : "Descripción por defecto"} />
                <meta itemProp="image" content={`${client ? client.image : ''}`} />

                <meta property="og:url" content="#" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={client ? client.name_show : ""} />
                <meta property="og:description" content={client ? client.description : "Descripción por defecto"} />
                <meta property="og:image" content={`${client ? client.image : ''}`} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={client ? client.name_show : ""} />
                <meta name="twitter:description" content={client ? client.description : "Descripción por defecto"} />
                <meta name="twitter:image" content={`${client ? client.image : ''}`} />
                <meta property="og:url" content="#" />
            </Helmet>
        </div>
    );
}

const mapStateToProps = (state) => ({
    client: state.app.client,
  });

export default connect(mapStateToProps)(Header);