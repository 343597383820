import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { cartSubtotalSelector } from 'redux/selectors/cart.selector';
import { getTotalAmount } from 'helpers/prices';

import Modal from 'components/commons/Modal';
import SecureCheckoutForm from 'components/forms/SecureCheckoutForm';

const SecureCheckoutContainer = (props) => {
  const {
    coupon, data: { callback, email, formData }, onClose, subtotal,
  } = props;

  const total = getTotalAmount({ coupon, subtotal });

  const onSubmit = async (card) => {
    setTimeout(() => {
      callback({ ...formData, card });
      onClose();
    }, 2500);
  };

  return (
    <Modal onClose={onClose}>
      <SecureCheckoutForm
        email={email}
        onSubmit={onSubmit}
        total={total}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  coupon: state.cart.coupon,
  subtotal: cartSubtotalSelector(state),
});

SecureCheckoutContainer.defaultProps = {
  coupon: null,
};

SecureCheckoutContainer.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
  }),
  data: PropTypes.shape({
    callback: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    formData: PropTypes.shape().isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  subtotal: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(SecureCheckoutContainer);
