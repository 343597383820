import { createSelector } from 'reselect';

const getCategories = (state) => state.category.items;
const getSubCategories = (state) => state.subCategory.items;
const getCategoryCode = (state, props) => props.match.params.category;
const getSubCategoryCode = (state, props) => props.match.params.subCategory;

export const categoriesSelector = createSelector([getCategories], (categories) => categories);

export const categorySelector = createSelector([getCategories, getCategoryCode],
  (categories, code) => (
    categories.find((category) => category.code === code, null)
  ));

export const subCategorySelector = createSelector([getSubCategories, getSubCategoryCode],
  (subCategories, code) => {
    return subCategories.find((category) => category.code === code)
  });
