export const PRODUCT_GET_ALL_REQUESTED = 'PRODUCT_GET_ALL_REQUESTED';
export const PRODUCT_GET_ALL_SUCCEEDED = 'PRODUCT_GET_ALL_SUCCEEDED';
export const PRODUCT_GET_ALL_FAILED = 'PRODUCT_GET_ALL_FAILED';

export const PRODUCT_GET_MAX_PRICE_REQUESTED = 'PRODUCT_GET_MAX_PRICE_REQUESTED';
export const PRODUCT_GET_MAX_PRICE_SUCCEEDED = 'PRODUCT_GET_MAX_PRICE_SUCCEEDED';
export const PRODUCT_GET_MAX_PRICE_FAILED = 'PRODUCT_GET_MAX_PRICE_FAILED';

export const PRODUCT_GET_MOST_SELLED_REQUESTED = 'PRODUCT_GET_MOST_SELLED_REQUESTED';
export const PRODUCT_GET_MOST_SELLED_SUCCEEDED = 'PRODUCT_GET_MOST_SELLED_SUCCEEDED';
export const PRODUCT_GET_MOST_SELLED_FAILED = 'PRODUCT_GET_MOST_SELLED_FAILED';

export const PRODUCT_GET_NEW_ARRIVALS_REQUESTED = 'PRODUCT_GET_NEW_ARRIVALS_REQUESTED';
export const PRODUCT_GET_NEW_ARRIVALS_SUCCEEDED = 'PRODUCT_GET_NEW_ARRIVALS_SUCCEEDED';
export const PRODUCT_GET_NEW_ARRIVALS_FAILED = 'PRODUCT_GET_NEW_ARRIVALS_FAILED';

export const PRODUCT_GET_OFFERS_REQUESTED = 'PRODUCT_GET_OFFERS_REQUESTED';
export const PRODUCT_GET_OFFERS_SUCCEEDED = 'PRODUCT_GET_OFFERS_SUCCEEDED';
export const PRODUCT_GET_OFFERS_FAILED = 'PRODUCT_GET_OFFERS_FAILED';

export const PRODUCT_GET_SEARCH_REQUESTED = 'PRODUCT_GET_SEARCH_REQUESTED';
export const PRODUCT_GET_SEARCH_SUCCEEDED = 'PRODUCT_GET_SEARCH_SUCCEEDED';
export const PRODUCT_GET_SEARCH_FAILED = 'PRODUCT_GET_SEARCH_FAILED';

export const PRODUCT_GET_SINGLE_REQUESTED = 'PRODUCT_GET_SINGLE_REQUESTED';
export const PRODUCT_GET_SINGLE_SUCCEEDED = 'PRODUCT_GET_SINGLE_SUCCEEDED';
export const PRODUCT_GET_SINGLE_FAILED = 'PRODUCT_GET_SINGLE_FAILED';

export const PRODUCT_GET_PRODUCTS_HOME_REQUESTED = 'PRODUCT_GET_PRODUCTSHOME_REQUESTED';
export const PRODUCT_GET_PRODUCTS_HOME_SUCCEEDED = 'PRODUCT_GET_PRODUCTSHOME_SUCCEEDED';
export const PRODUCT_GET_PRODUCTS_HOME_FAILED = 'PRODUCT_GET_PRODUCTSHOME_FAILED';

export const PRODUCT_GET_MOSTSELLED_HOME_REQUESTED = 'PRODUCT_GET_MOSTSELLED_HOME_REQUESTED';
export const PRODUCT_GET_MOSTSELLED_HOME_SUCCEEDED = 'PRODUCT_GET_MOSTSELLED_HOME_SUCCEEDED';
export const PRODUCT_GET_MOSTSELLED_HOME_FAILED = 'PRODUCT_GET_MOSTSELLED_HOME_FAILED';
