import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';

import Loading from 'components/commons/Loading';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import './LoginForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de email inválida';
  }
  if (!values.password) {
    errors.password = 'Contraseña inválida';
  }
  return errors;
};

const LoginForm = (props) => {
  const {
    handleSubmit, loading, openRegisterModal, openResetPasswordModal, onSubmit, submitting,
  } = props;

  return (
    <div className="container" data-testid="login-form">
      <div className="row">
        {loading && <Loading message="Cargando..." size={80} />}
        <div className={`form-container ${loading ? 'd-none' : ''}`}>
          <Avatar className="avatar">
            <Person />
          </Avatar>
          <h4>Iniciar sesión</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              component={Input}
              label="Email"
              name="email"
            />
            <Field
              component={Input}
              label="Password"
              name="password"
              type="password"
            />
            <div className="submit-button-container">
              <Button text="Enviar" type="submit" buttonClass="btn-block" disabled={submitting} />
            </div>
            <div className="small clearfix">
              <div className="form-extra float-left">
                No tenes una cuenta?
                <span
                  className="form-extra-link"
                  onClick={openRegisterModal}
                  role="presentation"
                >
                  {' Regístrate'}
                </span>
              </div>
              <div className="form-extra float-right">
                <span
                  className="form-extra-link"
                  onClick={openResetPasswordModal}
                  role="presentation"
                >
                  ¿Olvidaste la contraseña?
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  openRegisterModal: PropTypes.func.isRequired,
  openResetPasswordModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'login',
  validate,
})(LoginForm);
