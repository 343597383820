import React from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';

import Breadcrumb from 'components/commons/Breadcrumb';
import Loading from 'components/commons/Loading';
import CartSummary from 'components/commons/CartSummary';
import Benefit from 'components/commons/Benefit';

import './Checkout.scss';

const Checkout = (props) => {
  const {
    cartSize, items, loading, minimumPurchase, renderForm, shippingText, subtotal,
    url, urlText, coupon, subTotEnv,
  } = props;

  const showCart = cartSize > 0;

  return (
    <div data-testid="checkout-page">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/carrito', url]} names={['Carrito', urlText]} />
            <div className="row">
              {loading && <Loading size={80} />}
              {!loading && (
                showCart ? (
                  <>
                    <div className="col-md-7">
                      {renderForm()}
                    </div>
                    <div className="col-md-5 checkout-cart-summary">
                      <CartSummary
                        items={items}
                        shippingText={shippingText}
                        subtotal={subtotal}
                        coupon={coupon}
                        subTotEnv={!!subTotEnv ? subTotEnv : 0}
                      />
                    </div>
                    <div className="col">
                      <h4 className="text-center minimum-purchase" data-testid="minimum-purchase">
                        {`* Compra mínima: $${minimumPurchase}`}
                      </h4>
                    </div>
                  </>
                ) : (
                  <div className="col">
                    <h2 className="text-center" data-testid="empty-cart">
                      El carrito está vacío
                    </h2>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

Checkout.defaultProps = {
  coupon: null,
};

Checkout.propTypes = {
  cartSize: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  renderForm: PropTypes.func.isRequired,
  shippingText: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  urlText: PropTypes.string.isRequired,
};

export default Checkout;
