import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as appConstants from 'redux/constants/app.constants';
import * as constants from 'redux/constants/subCategory.constants';
import { getAll, getSubCategoriesHome } from 'services/subCategory.services';

export function* subCategoryGetAll() {
  try {
    const data = yield call(getAll);
    yield put({ type: constants.SUBCATEGORY_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.SUBCATEGORY_GET_ALL_FAILED, error });
  }
}

export function* subCategoryHomeGet() {
  try {
    const data = yield call(getSubCategoriesHome);
    yield put({ type: constants.SUBCATEGORY_GET_SUBCATEGORIES_HOME_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.SUBCATEGORY_GET_SUBCATEGORIES_HOME_FAILED, error });
  }
}

export function* watchSubCategories() {
  yield all([
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, subCategoryGetAll),
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, subCategoryHomeGet),
  ]);
}
