export const CATEGORY_GET_ALL_REQUESTED = 'CATEGORY_GET_ALL_REQUESTED';
export const CATEGORY_GET_ALL_SUCCEEDED = 'CATEGORY_GET_ALL_SUCCEEDED';
export const CATEGORY_GET_ALL_FAILED = 'CATEGORY_GET_ALL_FAILED';

export const CATEGORY_GET_CATEGORIES_HOME_REQUESTED = 'CATEGORY_GET_CATEGORIES_HOME_REQUESTED';
export const CATEGORY_GET_CATEGORIES_HOME_SUCCEEDED = 'CATEGORY_GET_CATEGORIES_HOME_SUCCEEDED';
export const CATEGORY_GET_CATEGORIES_HOME_FAILED = 'CATEGORY_GET_CATEGORIES_HOME_FAILED';

export const CATEGORY_GET_MARCAS_HOME_REQUESTED = 'CATEGORY_GET_MARCAS_HOME_REQUESTED';
export const CATEGORY_GET_MARCAS_HOME_SUCCEEDED = 'CATEGORY_GET_MARCAS_HOME_SUCCEEDED';
export const CATEGORY_GET_MARCAS_HOME_FAILED = 'CATEGORY_GET_MARCAS_HOME_FAILED';

export const CATEGORY_GET_CARTEL_HOME_REQUESTED = 'CATEGORY_GET_CARTEL_HOME_REQUESTED';
export const CATEGORY_GET_CARTEL_HOME_SUCCEEDED = 'CATEGORY_GET_CARTEL_HOME_SUCCEEDED';
export const CATEGORY_GET_CARTEL_HOME_FAILED = 'CATEGORY_GET_CARTEL_HOME_FAILED';
