import axios from 'axios';
import { API_ENDPOINT,CLIENT_ID } from 'constants/configs';
import { getCookie, saveUserCookies } from 'helpers/cookies';

const api = axios.create({
  baseURL: API_ENDPOINT,
});

// If token is expired
api.interceptors.response.use((response) => response, (error) => {
  const originalRequest = error.config;
  if ((error.response.status === 401) && !originalRequest.retry) {
    originalRequest.retry = true;
    return api.post('auth/refreshToken', { refreshToken: getCookie('refreshToken') }).then(({ data }) => {
      saveUserCookies(data.token);
      const accessToken = getCookie('accessToken');
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return axios(originalRequest);
    });
  }
  return Promise.reject(error);
});

const getOptions = () => {
  const accessToken = getCookie('accessToken');
  const options = {
    'Content-Type': 'application/json',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  };
  return options;
};

const errorResponse = (error) => {
  const { data: { message, errors } } = error.response;
  const response = new Error(message);
  response.errors = errors;
  return response;
};

export const post = async (url, params = {}, headers = {}) => {
  try {
    const options = { ...getOptions(), ...headers };
    const sendParams = {...params, client_id: CLIENT_ID};
    const { data } = await api.post(url, sendParams, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
};

export const get = async (url, headers = {}) => {
  try {
    const options = { ...getOptions(), ...headers, params:{client_id:CLIENT_ID} };
    const { data } = await api.get(url, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
};
