import React, { useEffect } from 'react';
import Logo from 'components/main/Header/Logo';
import { connect } from 'react-redux';

const LogoContainer = (props) => {
    const {client} = props;

  return (
  <>
    { client ? <Logo logoSrc={client.image} /> : <></>}
  </>
  );
};

const mapStateToProps = (state) => ({
    client: state.app.client,
  });

  export default connect(mapStateToProps)(LogoContainer);