import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from 'components/commons/Loading';

import './Preloader.scss';

const Preloader = (props) => {
  const { showPreloader,client } = props;
  useEffect(() => {
    document.body.style.overflow = (showPreloader) ? 'hidden' : 'auto';
  }, [showPreloader]);

  useEffect(()=>{
    if (client){
      client.color_prin && document.documentElement.style.setProperty('--color-base', client.color_prin.rgb);
      client.color_sec && document.documentElement.style.setProperty('--color-base-hover',client.color_sec.rgb);
      client.color_text && document.documentElement.style.setProperty('--color-text', client.color_text.rgb);
      client.color_text_selected && document.documentElement.style.setProperty('--color-text-selected',client.color_text_selected.rgb);
      client.color_title && document.documentElement.style.setProperty('--color-title',client.color_title.rgb);
    }
  },[client])

  return (
    <div className={`preloader ${(showPreloader) ? '' : 'hide'}`} data-testid="preloader">
      <Loading size={80} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  showPreloader: state.app.showPreloader,
  client: state.app.client,
});

Preloader.propTypes = {
  showPreloader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Preloader);
