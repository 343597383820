import * as userConstants from 'redux/constants/user.constants';
import * as cartConstants from 'redux/constants/cart.constants';
import * as productConstants from 'redux/constants/product.constants';
import { saveUserCookies, removeUserCookies } from 'helpers/cookies';
import {
  getLocalStorage, setLocalStorage, removeLocalStorage, clearLocalStorage,
} from 'helpers/storage';
import { updateCart, removeItemFromCart } from 'helpers/cart';
import { sendSuccessNotification, sendErrorNotification } from 'helpers/notifications';

const dispatchCartChanged = (store, items, itemsSub) => {
  const { dispatch } = store;
  setLocalStorage('lenceria-cart', items);
  setLocalStorage('lenceria-sub-cart', itemsSub);

  dispatch({
    type: cartConstants.CART_UPDATE_SUCCEEDED,
    items,
    itemsSub,
  });
};

const updateCartItems = (store, action) => {
  const { cart } = store.getState();

  if (action.wholesale?.quantity > 0 && action.wholesale?.bool) {
    const filterCondition = (item) => (
      item.itemId === action.itemId && item.descriptionId !== action.descriptionId
      && item.wholesale.id === action.wholesale.id // ACA NOSE SI PONER TMB EL ID
    );

    const sumaSubQuantity = action.descriptionId
      ? (cart.itemsSub
        .filter(filterCondition)
        .reduce((acumulador, item) => acumulador + item.quantity, 0))
      : 0;
    const sumaQuantity = action.descriptionId
      ? (cart.items
        .filter(filterCondition)
        .reduce((acumulador, item) => acumulador + item.quantity, 0))
      : 0;
    const sumaTot = action.descriptionId
      ? sumaSubQuantity + sumaQuantity + action.quantity : action.quantity;
    
      const uniqueTalles = [...cart.items, ...cart.itemsSub]
      .filter(item => item.itemId === action.itemId && item.wholesale.id === action.wholesale.id)
      .reduce((acc, item) => {
        if (item.descriptionId !== action.descriptionId) {
          const talleExists = acc.find(t => t.talle === item.talle);
          if (!talleExists) {
            acc.push({ talle: item.talle, quantity: item.quantity });
          } else {
            talleExists.quantity += item.quantity; 
          }
        }
        return acc;
      }, []);
    
    if (action.quantity > 0) {
      const actionTalleExists = uniqueTalles.find(t => t.talle === action.talle);
      if (actionTalleExists) {
        actionTalleExists.quantity += action.quantity;
      } else {
        uniqueTalles.push({ talle: action.talle, quantity: action.quantity });
      }
    }
    
    const cantidadTallesDiferentes = uniqueTalles.length;
      
    if ((sumaTot < action.wholesale?.quantity || (sumaTot % action.wholesale?.quantity) !== 0) && !action.wholesale?.curva) {
      const items = updateCart(
        cart.items, action.itemId, action.quantity, action.descriptionId, action.wholesale, action.talle,
      );
      let itemsSub = updateCart(
        cart.itemsSub, action.itemId, action.quantity, action.descriptionId, action.wholesale, action.talle,
      );

      // Si ya tenia otro producto
      const objetosConMismoID = items.filter(filterCondition);
      objetosConMismoID.forEach((item) => {
        if (action.wholesale.id === item.wholesale.id) {
          itemsSub = updateCart(
            itemsSub, item.itemId, item.quantity, item.descriptionId, item.wholesale, item.talle,
          );
        }
      });
      const newItemsSub = (action.quantity === 0)
        ? removeItemFromCart(itemsSub, action.itemId, action.descriptionId, action.wholesale, action.talle)
        : itemsSub;

      const newItems = removeItemFromCart(cart.items, action.itemId, null, action.wholesale, action.talle);
      dispatchCartChanged(store, newItems, newItemsSub);
      if (action.showNotification) {
        const rest = action.wholesale?.quantity - sumaTot;
        if (rest > 0) {
          sendErrorNotification(`Debes agregar ${rest} productos mas para que se agregue al carrito`);
        } else {
          const rest2 = sumaTot % action.wholesale?.quantity;
          const proximoMultiplo = action.wholesale?.quantity - rest2;
          sendErrorNotification(`Debes agregar ${proximoMultiplo} productos mas para que se agregue al carrito o sacar ${rest2} del carrito`);
        }
      }
    } else if((action.wholesale.curva && (!uniqueTalles.every(item => item.quantity === uniqueTalles[0].quantity) || cantidadTallesDiferentes !== action.wholesale.quantity || sumaTot % action.wholesale?.quantity) !== 0)){
        const items = updateCart(
          cart.items, action.itemId, action.quantity, action.descriptionId, action.wholesale, action.talle,
        );
        let itemsSub = updateCart(
          cart.itemsSub, action.itemId, action.quantity, action.descriptionId, action.wholesale, action.talle,
        );
  
        // Si ya tenia otro producto
        const objetosConMismoID = items.filter(filterCondition);
        objetosConMismoID.forEach((item) => {
          if (action.wholesale.id === item.wholesale.id) {
            itemsSub = updateCart(
              itemsSub, item.itemId, item.quantity, item.descriptionId, item.wholesale, item.talle,
            );
          }
        });
        const newItemsSub = (action.quantity === 0)
          ? removeItemFromCart(itemsSub, action.itemId, action.descriptionId, action.wholesale, action.talle)
          : itemsSub;
  
        const newItems = removeItemFromCart(cart.items, action.itemId, null, action.wholesale, action.talle);
        dispatchCartChanged(store, newItems, newItemsSub);
        if (action.showNotification) {
          const rest = action.wholesale?.quantity - sumaTot;
          if (cantidadTallesDiferentes !== action.wholesale.quantity) {
            sendErrorNotification(`Debes agregar todos los talles al carrito para cumplir la curva`);
          } else if(!uniqueTalles.every(item => item.quantity === uniqueTalles[0].quantity)) {
            sendErrorNotification(`Debes agregar una misma cantidad de productos por talle al carrito`);
          }
        }
      } 
    else {
      const itemsSubCart = updateCart(
        cart.itemsSub, action.itemId, action.quantity, action.descriptionId, action.wholesale, action.talle,
      );
      let items = updateCart(
        cart.items, action.itemId, action.quantity, action.descriptionId, action.wholesale, action.talle,
      );
      // Si ya tenia otro producto
      const objetosConMismoID = itemsSubCart.filter(filterCondition);

      objetosConMismoID.forEach((item) => {
        if (action.wholesale.id === item.wholesale.id) {
          items = updateCart(
            items, item.itemId, item.quantity, item.descriptionId, item.wholesale, item.talle,
          );
        }
      });
      const newItems = (action.quantity === 0)
        ? removeItemFromCart(items, action.itemId, action.descriptionId, action.wholesale, action.talle) : items;
      // elimino los productos si estaba en lenceria-sub-cart
      const newItemsSub = removeItemFromCart(cart.itemsSub, action.itemId, null, action.wholesale, action.talle, action.talle);

      dispatchCartChanged(store, newItems, newItemsSub);
      if (action.showNotification) {
        sendSuccessNotification('Producto agregado al carrito correctamente');
      }
    }
  } else {
    const items = updateCart(
      cart.items, action.itemId, action.quantity, action.descriptionId, action.wholesale, action.talle
    );
    dispatchCartChanged(store, items, cart.itemsSub);
    if (action.showNotification) {
      if (action.quantity > 0) sendSuccessNotification('Producto agregado al carrito correctamente');
      else sendErrorNotification('Producto eliminado del carrito correctamente');
    }
  }
};

const initializeCart = (store, products) => {
  const items = getLocalStorage('lenceria-cart') || [];
  const itemsSub = getLocalStorage('lenceria-sub-cart') || [];

  const cart = [];
  const cartSub = [];
  items.forEach(({
    itemId, quantity, descriptionId, wholesale, talle,
  }) => {
    const product = products.find((prod) => prod.id === itemId, null);
    if (product) {
      cart.push({
        itemId: product.id,
        quantity: quantity > product.stock ? product.stock : quantity,
        descriptionId,
        wholesale,
        talle,
      });
    }
  });
  // CARRITO PARA GUARDAR LO NO VALIDADO
  itemsSub.forEach(({
    itemId, quantity, descriptionId, wholesale,
  }) => {
    const product = products.find((prod) => prod.id === itemId, null);
    if (product) {
      cartSub.push({
        itemId: product.id,
        quantity: quantity > product.stock ? product.stock : quantity,
        descriptionId,
        wholesale,
      });
    }
  });
  dispatchCartChanged(store, cart, cartSub);
};

const storageMiddleware = (store) => (next) => (action) => {
  const { data, type } = action;
  switch (type) {
    case productConstants.PRODUCT_GET_ALL_SUCCEEDED:
      initializeCart(store, data.items);
      break;
    case userConstants.USER_LOGIN_SUCCEEDED:
      saveUserCookies(data.token);
      break;
    case cartConstants.CART_UPDATE_REQUESTED:
      updateCartItems(store, action);
      break;
    case cartConstants.CART_CHECKOUT_SUCCEEDED:
      removeLocalStorage('lenceria-cart');
      // FIJARSE PARA MI NO
      removeLocalStorage('lenceria-sub-cart');
      break;
    case userConstants.USER_LOGOUT_SUCCEEDED:
      clearLocalStorage();
      removeUserCookies();
      break;
    default:
      break;
  }
  return next(action);
};

export default storageMiddleware;
