import { sendErrorNotification } from 'helpers/notifications';

export const updateItemFromCart = (items, itemId, quantity, descriptionId, wholesale,talle) => {
  let cartHaschanged = false;
  const newItem = {
    itemId, quantity, wholesale,
  };
  const newItemDescription = {
    itemId, quantity, descriptionId, wholesale, talle,
  };
  const cartItems = items.map((cartItem) => {
    if (cartItem.itemId === newItem.itemId) {
      if (descriptionId) {
        if (cartItem.descriptionId === newItemDescription.descriptionId
          && cartItem.wholesale?.id === newItemDescription.wholesale?.id
          && cartItem.wholesale?.bool === newItemDescription.wholesale?.bool) {
          cartHaschanged = true;
          return newItemDescription;
        }
        return cartItem;
      }
      if (!(cartItem.wholesale.id === newItem.wholesale.id)) {
        return cartItem;
      }
      cartHaschanged = true;
      return newItem;
    }
    return cartItem;
  }, items);
  if (!cartHaschanged) {
    if (descriptionId) {
      cartItems.push(newItemDescription);
    } else {
      cartItems.push(newItem);
    }
  }
  return cartItems;
};

export const removeItemFromCart = (items, itemId, descriptionId, wholesale) => {
  if (wholesale == null) {
    return items.filter((item) => !(item.itemId === itemId));
  }
  if (descriptionId) {
    return items.filter((item) => !(item.descriptionId === descriptionId
        && item.wholesale.id === wholesale.id && item.itemId === itemId));
  }
  return items.filter((item) => !(item.itemId === itemId && item.wholesale.id === wholesale.id));
};

export const updateCart = (items, itemId, quantity, descriptionId, wholesale,talle) => (
  quantity > 0
    ? updateItemFromCart(items, itemId, quantity, descriptionId, wholesale,talle)
    : removeItemFromCart(items, itemId, descriptionId, wholesale));

export const isAbleToPurchase = (subtotal, minimumPurchase) => {
  const canPurchase = subtotal >= minimumPurchase;
  if (!canPurchase) {
    sendErrorNotification(`La compra mínima es de $${minimumPurchase}`);
  }
  return canPurchase;
};
