import React, { Fragment, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Zoom from 'react-medium-image-zoom';
import PropTypes from 'prop-types';

import { productPropTypes } from 'helpers/propTypes';
import { getCookie, setCookie } from 'helpers/cookies';
import { setVisitsProducts } from 'services/user.services';
import Table from 'react-bootstrap/Table';

import DescriptionItem from 'components/commons/DescriptionItem';
import QuantitySelector from 'components/commons/QuantitySelector';

import remera from './assets/remera.png';
import box from './assets/box.png';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-medium-image-zoom/dist/styles.css';
import './Product.scss';

const Product = (props) => {
  const {
    images, item, onProductClick, onProductCopyClick, onQuantityChange,
    singleProductFullUrl, cartItemDescriptions, colors, combinations, wholesale,
    setWholesale, productCart, productCartSub, cartItemSubDescriptions, handleColorSelect,
    talles, combinationsTalle, selectedColor,getValidPriceRange, show,
  } = props;

  const quantity = productCart ? productCart.quantity : (productCartSub?.quantity || 0);
  const findProductWholesale = (id) => {
    const product = item.product_wholesaler.find((prod) => prod.id === id);
    return product.price * product.quantity;
  };
  const otherProducts = cartItemDescriptions.filter((data) => (data.wholesale.id !== wholesale.id));
  const hasNonNullPrice = talles.some(talle => talle.price !== null && talle?.price > 0);

  const addProduct = async (arrCount) => {
    const existProduct = arrCount.includes(item.id);
    if (!existProduct) {
      arrCount.push(item.id);
      setCookie('visit_product', arrCount, { maxAge: 86400 });
      const data = {
        id: item.id,
      };
      await setVisitsProducts(data);
    }
  };

  const updateCookie = async () => {
    try {
      if (getCookie('visit_product')) {
        const arrCount = getCookie('visit_product');
        addProduct(arrCount);
      } else {
        const arrCount = [];
        addProduct(arrCount);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    updateCookie();
  });

  const renderProductQuantitySelector = () => {
    if (item.stock_product_descriptions.length > 0) {
      return (
        <div class="table-responsive">
        <Table  responsive className="colors-table">
          <thead>
            <tr>
              <th className="th-1">Color/ Talle</th>
              { Object.values(combinations).some(array => array.length > 0) &&
                <>
                {Object.keys(combinationsTalle)?.map((id) => {
                  const talle = talles.find(({ stock_description_id }) => stock_description_id === Number(id));
                  if (Object.keys(talle).length > 0) {
                    return (
                      <>
                        <th className="th-2">
                          <span>{talle.stock_descriptions.name}</span>
                        </th>
                      </>
                    );
                  }
                  return (
                    <th className="th-2">
                      <span>Sin talle</span>
                    </th>
                  );
                })}
                </>
              }
              {!Object.values(combinations).some(array => array.length > 0) && colors.length > 0 && (
                <th className="th-2">
                  <span>Unico</span>
                </th>
              )}
              {!Object.values(combinations).some(array => array.length > 0) && talles.length > 0 && (
                <th className="th-2">
                  <span>Unico</span>
                </th>
              )}
            </tr>
            <tr>
              
              { (hasNonNullPrice && Object.values(combinations).some(array => array.length > 0)) &&
                <>
                  <> 
                    <th className="th-1">
                      <span>Precio</span>
                    </th>
                  </>
                {(Object.keys(combinationsTalle)?.map((id) => {
                    const talle = talles.find(({ stock_description_id }) => stock_description_id === Number(id));
                    if (Object.keys(talle).length > 0 && talle?.price !== null && talle?.price > 0) {
                      return (
                        <>
                          <th className="th-2">
                            <span>{show ? '$' + talle.price : '-'}</span>
                          </th>
                        </>
                      );
                    }
                    return (
                      <th className="th-2">
                        <span>{show ? '$' + item.price : '-'}</span>
                      </th>
                    );
                  })
                  )}
                </>
              }
            </tr>
          </thead>
          <tbody>
            {Object.values(combinations).some(array => array.length > 0) && (
            <>
            {Object.keys(combinations)?.map((colorId) => {
              const color = colors.find(({ stock_description_id }) => stock_description_id === Number(colorId));
              const descriptions = combinations[colorId];
              const isMulticolor = color.stock_descriptions.name === "Multicolor";

              return (
                <tr key={`${item.id}-${colorId}`}>
                  <td className="color__item" data-color-id="13" data-color-name="Negro">
                    <div className="color-container">
                      <span 
                        className={`color-circle ${isMulticolor ? 'multicolor' : ''} ${/*selectedColor?.id === color.id ? 'selected' : */''}`}
                        onClick={() => handleColorSelect(color)}
                        style={isMulticolor ? {} : { backgroundColor: color.stock_descriptions.rgb }}
                        />
                    </div>
                    <span className="color-name">{color.stock_descriptions.name}</span>
                  </td>
                  {Object.keys(combinationsTalle)?.map((id) => {
                    const description = descriptions.find(({ stock_description_id }) => stock_description_id === Number(id));
                    return (
                      <td className="td-2" >
                        <li className="float-xs-left">
                          {description && description.stock > 0 && (
                          <DescriptionItem
                            description={description}
                            handleColorSelect={handleColorSelect}
                            onQuantityChange={onQuantityChange}
                            showQuantitySelector={descriptions.length === 0}
                            storage={cartItemDescriptions.find(
                              (data) => (
                                data.descriptionId === description.id && data.wholesale.id === wholesale.id
                              ),
                            )}
                            storageSub={cartItemSubDescriptions.find(
                              (data) => (
                                data.descriptionId === description.id && data.wholesale.id === wholesale.id
                              ),
                            )}
                            othersWholesale={cartItemDescriptions.filter(
                              (data) => (
                                data.descriptionId === description.id && data.wholesale.id !== wholesale.id
                              ),
                            )}
                            othersWholesaleSub={cartItemSubDescriptions.filter(
                              (data) => (
                                data.descriptionId === description.id && data.wholesale.id !== wholesale.id
                              ),
                            )}
                            show={show}
                          />
                          )}
                        </li>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            </>
            )}
            {!Object.values(combinations).some(array => array.length > 0) && colors?.length > 0 && (
              <>
              {colors.map((color) => (
                <tr key={`${item.id}-${color.id}-${item.name}`}>
                  <td className="color__item" data-color-id="13" data-color-name="Negro">
                    <div className="color-container">
                      <span className="color-circle" style={{ backgroundColor: color.stock_descriptions.rgb }} />
                      <span className="color-name">{color.stock_descriptions.name}</span>
                    </div>
                  </td>
                  <td className="td-2" key={`${item.id}-${color.id}`}>
                    <li className="float-xs-left">
                      <DescriptionItem
                        description={color}
                        handleColorSelect={handleColorSelect}
                        onQuantityChange={onQuantityChange}
                        showQuantitySelector={combinations[color.id].length === 0}
                        storage={cartItemDescriptions.find(
                          (data) => (
                            data.descriptionId === color.id && data.wholesale.id === wholesale.id
                          ),
                        )}
                        storageSub={cartItemSubDescriptions.find(
                          (data) => (
                            data.descriptionId === color.id && data.wholesale.id === wholesale.id
                          ),
                        )}
                        othersWholesale={cartItemDescriptions.filter(
                          (data) => (
                            data.descriptionId === color.id && data.wholesale.id !== wholesale.id
                          ),
                        )}
                        othersWholesaleSub={cartItemSubDescriptions.filter(
                          (data) => (
                            data.descriptionId === color.id && data.wholesale.id !== wholesale.id
                          ),
                        )}
                        show={show}
                      />
                    </li>
                  </td>
                </tr>
              ))}
              </>
            )}
            {!Object.values(combinations).some(array => array.length > 0) && talles.length > 0 && (
              <>
                {talles.map((talle) => (
                  <tr key={`${item.id}-${talle.id}-${item.name}`}>
                    <td className="color__item" data-color-id="13" data-color-name="Negro">
                      <div className="color-container">
                        <span className="color-name">{talle.stock_descriptions.name}</span>
                        {(talle?.price > 0) && (
                          <span className="color-name">- ${talle.price}</span>
                        )}
                      </div>
                    </td>
                    <td className="td-2" key={`${item.id}-${talle.id}`}>
                      <li className="float-xs-left">
                        <DescriptionItem
                          description={talle}
                          handleColorSelect={handleColorSelect}
                          onQuantityChange={onQuantityChange}
                          showQuantitySelector={combinations[talle.id].length === 0}
                          storage={cartItemDescriptions.find(
                            (data) => (
                              data.descriptionId === talle.id && data.wholesale.id === wholesale.id
                            ),
                          )}
                          storageSub={cartItemSubDescriptions.find(
                            (data) => (
                              data.descriptionId === talle.id && data.wholesale.id === wholesale.id
                            ),
                          )}
                          othersWholesale={cartItemDescriptions.filter(
                            (data) => (
                              data.descriptionId === talle.id && data.wholesale.id !== wholesale.id
                            ),
                          )}
                          othersWholesaleSub={cartItemSubDescriptions.filter(
                            (data) => (
                              data.descriptionId === talle.id && data.wholesale.id !== wholesale.id
                            ),
                          )}
                          show={show}
                        />
                      </li>
                    </td>
                  </tr>
                ))}
              </>
            )}            
          </tbody>
        </Table>
        </div>
      );
    }
    if (item.stock > 0) {
      return (
        <QuantitySelector
        limit={item.stock}
        onQuantityChange={onQuantityChange}
        quantity={quantity}
        quantityOthersWholesale={otherProducts.map((otherProduct) => (otherProduct.quantity))}
          show={show}
        />
      );
    }

    return <span className="product-details-out-of-stock">Sin stock</span>;
  };

  return (
    <div data-testid="product-modal">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="product-details-image-container">
              <Carousel
                autoPlay
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                showThumbs
                swipeable={false}
              >
                {images.map((image) => (
                  <Zoom key={`zoom-image-${image.id}`}>
                    <img alt={item.name} src={image} width="250" />
                  </Zoom>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="product-details">
              <div>
                <div>
                  <span
                    className="product-details-name"
                    onClick={onProductClick}
                    role="presentation"
                  >
                    {item.name}
                  </span>
                  <CopyToClipboard
                    text={singleProductFullUrl}
                    onCopy={onProductCopyClick}
                  >
                    <i
                      className="fa fa-clone product-details-copy-icon"
                      aria-hidden="true"
                      title="Copiar link del producto"
                    />
                  </CopyToClipboard>
                </div>
                <div className="container-mayorista">
                  {item.product_wholesaler.length > 0 ? (
                    <>
                      <button onClick={() => setWholesale({ id: 0, bool: false, quantity: 0, curva: false })} className={!wholesale.bool ? 'circular-button-selected circular-button' : 'circular-button'} type="button">
                        <span className={`button-text ${!wholesale.bool && 'animacion-selected'}`}>
                          Precio estandar
                        </span>
                      </button>
                      {item.product_wholesaler.map((prod) => (
                        <button onClick={() => setWholesale({ id: prod.id, bool: true, quantity: prod.quantity, curva: prod.curva })} className={(wholesale.bool && wholesale.id === prod.id) ? 'circular-button-selected circular-button' : 'circular-button'} type="button">
                          <span className={`button-text ${(wholesale.bool && wholesale.id === prod.id) && 'animacion-selected'}`}>
                            {prod.comboName ?? "Precio por " + prod.quantity}
                          </span>
                        </button>
                      ))}
                    </>
                  )
                    : (
                      <>
                        <button className="circular-button" type="button">
                          <span className="button-text animacion-selected">
                            Precio estandar
                          </span>
                        </button>
                      </>
                    )}
                </div>
              </div>
              {!wholesale.bool && item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
              <div className="product-details-section">
                {item.product_wholesaler.length > 0 ? (
                  <div className="product-details-price">
                    {wholesale.bool ? show && `$${(findProductWholesale(wholesale.id)).toLocaleString('de-DE')}` : show && `$${item.price.toLocaleString('de-DE')}`}
                  </div>
                ) : (
                  <div className="product-details-price">
                    { !hasNonNullPrice ? (
                      <>
                      {item.sale > 0
                        ? (
                          <>
                            <div className="price">
                              {`$${item.sale}`}
                            </div>
                            <span className="discount">{show && `$${item.price}`}</span>
                          </>
                        )
                        : <div className="price">{show && `$${item.price}`}</div>}
                      </>
                    ) : (
                      <div className="price">{show && getValidPriceRange()}</div>
                  ) }
                  </div>
                )}
              </div>
              <div className="separator" />
              <div className="product-details-section">
                {renderProductQuantitySelector()}
              </div>
              <div className="product-details-section">
                <span className="product-details-description"><pre>{item.description}</pre></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Product.defaultProps = {
  combinations: {},
  handleColorSelect: () => {},
  productCart: null,
  productCartSub: null,
};

Product.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  combinations: PropTypes.shape(),
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  cartItemDescriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cartItemSubDescriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleColorSelect: PropTypes.func,
  item: productPropTypes.isRequired,
  onProductClick: PropTypes.func.isRequired,
  onProductCopyClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  singleProductFullUrl: PropTypes.string.isRequired,
  wholesale: PropTypes.shape().isRequired,
  setWholesale: PropTypes.func.isRequired,
  productCart: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  productCartSub: PropTypes.shape({
    quantity: PropTypes.number,
  }),
};

export default Product;
