import React from 'react';
import { connect } from 'react-redux';

import { productsArrayPropTypes } from 'helpers/propTypes';
import PaginatorContainer from 'containers/PaginatorContainer';

const ProductsContainer = (props) => {
  const { items } = props;

  return (
    <PaginatorContainer
      name="Productos"
      products={items}
      url="/productos"
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  items: state.product.items,
});

ProductsContainer.propTypes = {
  items: productsArrayPropTypes.isRequired,
};

export default connect(mapStateToProps)(ProductsContainer);
