import * as constants from 'redux/constants/subCategory.constants';

const defaultState = {
  items: [],
  itemsHome: [],
  loading: false,
};

const subCategoryReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.SUBCATEGORY_GET_ALL_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.SUBCATEGORY_GET_SUBCATEGORIES_HOME_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.SUBCATEGORY_GET_ALL_SUCCEEDED:
      return {
        ...state,
        items: data.items,
        loading: false,
      };
    case constants.SUBCATEGORY_GET_SUBCATEGORIES_HOME_SUCCEEDED:
      return {
        ...state,
        itemsHome: data.items,
        loading: false,
      };
    case constants.SUBCATEGORY_GET_ALL_FAILED:
      return defaultState;
    case constants.SUBCATEGORY_GET_SUBCATEGORIES_HOME_FAILED:
      return defaultState;
    default:
      return state;
  }
};

export default subCategoryReducer;
