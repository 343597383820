import React from 'react';
import PropTypes from 'prop-types';

import './Benefit.scss';

const Benefit = ({ minimumPurchase }) => (
  <div className="container" data-testid="benefit">
    <div className="benefit">
      <div className="row benefit-row">
        <div className="col-lg-4 benefit-col">
          <div className="benefit-item">
            <div className="benefit-icon">
              <i className="fa fa-truck" aria-hidden="true" />
            </div>
            <div className="benefit-content">
              <p>Env&iacute;os a todo el pa&iacute;s</p>
              <h6>
                Monto m&iacute;nimo de compra $
                {minimumPurchase}
              </h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 benefit-col">
          <div className="benefit-item">
            <div className="benefit-icon">
              <i className="fa fa-credit-card" aria-hidden="true" />
            </div>
            <div className="benefit-content">
              <p>M&eacute;todos de pago</p>
              <h6>Transferencia, dep&oacute;sito, efectivo, Mercado Pago</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 benefit-col">
          <div className="benefit-item">
            <div className="benefit-icon">
              <i className="fa fa-clock-o" aria-hidden="true" />
            </div>
            <div className="benefit-content">
              <p>Env&iacute;os en el d&iacute;a</p>
              <h6>En CABA y alrededores</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Benefit.propTypes = {
  minimumPurchase: PropTypes.number.isRequired,
};

export default Benefit;
