import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { categoriesArrayPropTypes, productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import { MOST_SELLED, NEW_ARRIVALS, OFFERS } from 'views/Home/constants';
import { sendNewsletter } from 'redux/actions/user.actions';
import { getProductsHome, getMostSelledHome } from 'redux/actions/product.actions';

import ItemContainer from 'containers/ItemContainer';
import Home from 'views/Home';

const HomeContainer = (props) => {
  const {
    cartel, categories, categoriesHome, instagram, loadingProd, loadingMostSelled, marcasHome, newArrivals,
    minimumPurchase, offers, sliders, sendNewsletterAction, subCategoriesHome, mostSelled, loadingOffers, client,
    loading
  } = props;
  const initialSection = client && !!client.design ? -1 : NEW_ARRIVALS;
  const [currentSection, setCurrentSeccion] = useState(initialSection);
  const [currentSection1, setCurrentSeccion1] = useState(-1);
  const [newsletter, setNewsletter] = useState('');
  const [items, setItems] = useState(newArrivals);
    
  const setNewSection = (newSection) => { 
    switch (newSection) {
      case OFFERS.value:
        setCurrentSeccion(OFFERS);
        setItems(offers);
        break;
      case MOST_SELLED.value:
        setCurrentSeccion(MOST_SELLED);
        setItems(mostSelled);
        break;
      default:
        setCurrentSeccion(NEW_ARRIVALS);
        setItems(newArrivals);
        break;
    }
  }; 

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    if(client && !client.design)setNewSection(NEW_ARRIVALS)
    // eslint-disable-next-line
  }, [mostSelled, newArrivals, offers]);

  const filterItems = (subcat) => {
    if (subcat) {
      setCurrentSeccion(subcat.subCategory_id);
    } else {
      setCurrentSeccion(-1);
    }
  };

  const filterItems1 = (subcat) => {
    if (subcat) {
      setCurrentSeccion1(subcat.subCategory_id);
    } else {
      setCurrentSeccion1(-1);
    }
  };

  const renderItem = (item) => (
    <div className={`col-product${client && !!client.design ? '' : '-simple'}`} style={{ background: 'rgb(249 249 249)' }} key={`item-${item.id}`}>
      <ItemContainer item={item} />
    </div>
  );

  const submitNewsletter = () => {
    const value = { email: newsletter };
    sendNewsletterAction(value);
  };

  return (
    <Home
      cartel={cartel}
      categories={categories}
      categoriesHome={categoriesHome}
      subCategoriesHome={subCategoriesHome}
      currentSection={currentSection}
      currentSection1={currentSection1}
      instagram={instagram}
      items={client && !!client.design ? newArrivals : items}
      mostSelled={mostSelled}
      offers={offers}
      loadingProd={loadingProd}
      loadingMostSelled = {loadingMostSelled}
      loadingOffers = {loadingOffers}
      loading={loading}
      marcasHome={marcasHome}
      minimumPurchase={minimumPurchase}
      onSectionClick={client && !!client.design ? filterItems : setNewSection}
      mostSelledClick={filterItems1}
      renderItem={renderItem}
      sliders={sliders}
      submitNewsletter={submitNewsletter}
      setNewsletter={setNewsletter}
      tiktok="7132849512812416261"
      client={client}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendNewsletterAction: bindActionCreators(sendNewsletter, dispatch),
});

const mapStateToProps = (state, props) => ({
  cartel: state.category.cartelHome,
  categories: state.category.items,
  client: state.app.client,
  categoriesHome: state.category.itemsHome,
  instagram: state.app.instagram,
  products: state.product.items,
  loadingProd: state.product.loadingProd,
  loadingMostSelled: state.product.loadingMostSelled,
  loadingOffers: state.product.loadingOffers,
  marcasHome: state.category.marcasHome,
  minimumPurchase: state.app.minimumPurchase,
  mostSelled: state.product.mostSelled,
  newArrivals: state.product.newArrivals,
  offers: state.product.offers,
  section: props.match.params.section,
  sliders: state.app.sliders,
  subCategories: state.subCategory.items,
  subCategoriesHome: state.subCategory.itemsHome,
  loadingNewsletter: state.user.loadingNewsletter,
  loading: state.product.loading,
});

HomeContainer.defaultProps = {
  // section: '',
};

HomeContainer.propTypes = {
  cartel: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  categoriesHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  instagram: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  // loadingNewsletter: PropTypes.bool.isRequired,
  marcasHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  mostSelled: productsArrayPropTypes.isRequired,
  newArrivals: productsArrayPropTypes.isRequired,
  offers: productsArrayPropTypes.isRequired,
  products: productsArrayPropTypes.isRequired,
  // section: PropTypes.string,
  sendNewsletterAction: PropTypes.func.isRequired,
  sliders: slidersArrayPropTypes.isRequired,
  subCategoriesHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
