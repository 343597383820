import { getLocalStorage } from 'helpers/storage';
import { post } from 'services/api';

export const delivery = async (values) => {
  const data = {
    ...values,
    colors: getLocalStorage('descriptionsCart') ?? [],
  };
  const response = await post('checkout/delivery', data);

  if(values.formData.comprobante){
    const formData = new FormData();
    formData.append('comprobante', values.formData.comprobante[0]);
    formData.append('order_id', response.orderId);
    post('checkout/comprobante', formData, { transformRequest: () => formData });
    }
  return response;
};

export const pickup = async (values) => {
  const data = {
    ...values,
    colors: getLocalStorage('descriptionsCart') ?? [],
  };

  const response = await post('checkout/pickup', data);

  if(values.formData.comprobante){
  const formData = new FormData();
  formData.append('comprobante', values.formData.comprobante[0]);
  formData.append('order_id', response.orderId);
  post('checkout/comprobante', formData, { transformRequest: () => formData });
  }

  return response;
};

export const submitCoupon = async (coupon, email) => {
  const response = await post('searchCoupon', { coupon, email });
  return response;
};
