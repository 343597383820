import * as constants from 'redux/constants/modal.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  active: null,
  data: null,
};

const modalReducer = (state = defaultState, action) => {
  const { type } = action;
  switch (type) {
    case constants.MODAL_OPEN:
      return {
        ...state,
        active: action.modal,
        data: action.data,
      };
    case constants.MODAL_CLOSE:
    case userConstants.USER_REGISTER_SUCCEEDED:
    case userConstants.USER_LOGIN_SUCCEEDED:
    case userConstants.USER_RESET_PASSWORD_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default modalReducer;
