import * as constants from 'redux/constants/cart.constants';

export const updateCart = (
  itemId, quantity, descriptionId, wholesale, talle, showNotification = true 
) => ({
    type: constants.CART_UPDATE_REQUESTED,
    itemId,
    quantity,
    descriptionId,
    wholesale,
    showNotification,
    talle,
  }
);

export const checkout = (type, formData, items, userId) => ({
  type,
  formData,
  items,
  userId,
});

export const submitCoupon = (coupon, email) => ({
  type: constants.CART_COUPON_REQUESTED,
  coupon,
  email,
});

export const deleteCoupon = () => ({
  type: constants.CART_COUPON_DELETE,
});

export const getProducts = (value) => ({
  type: constants.CART_GET_PRODUCTS_REQUESTED,
  value,
});
