import React from 'react';
import PropTypes from 'prop-types';

import { purchaseHistoryArrayPropTypes } from 'helpers/propTypes';

import Breadcrumb from 'components/commons/Breadcrumb';
import Loading from 'components/commons/Loading';
import CustomTable from 'components/commons/CustomTable';
import Benefit from 'components/commons/Benefit';

import PurchaseHistoryRow from './components';

const PurchaseHistory = (props) => {
  const {
    loading, minimumPurchase, onRepeatAllProductsClick, onRepeatProductClick, purchaseHistory,
    onDownloadOrderPDF,
  } = props;

  const renderRow = (purchase) => (
    <PurchaseHistoryRow
      key={`purchase-${purchase.orderId}`}
      onRepeatAllProductsClick={onRepeatAllProductsClick}
      onRepeatProductClick={onRepeatProductClick}
      purchase={purchase}
      onDownloadOrderPDF={onDownloadOrderPDF}
    />
  );

  return (
    <div data-testid="purchase-history-page">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/compras']} names={['Mis compras']} />
            <div className="row">
              {loading && <Loading message="Cargando..." size={80} />}
              {!loading && (
                <CustomTable
                  renderRow={renderRow}
                  rows={purchaseHistory}
                  titles={['', 'Número de pedido', 'Fecha', 'Método de pago', 'Estado', 'Total', 'PDF', '']}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

PurchaseHistory.propTypes = {
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onRepeatAllProductsClick: PropTypes.func.isRequired,
  onRepeatProductClick: PropTypes.func.isRequired,
  onDownloadOrderPDF: PropTypes.func.isRequired,
  purchaseHistory: purchaseHistoryArrayPropTypes.isRequired,
};

export default PurchaseHistory;
