import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { contact } from 'redux/actions/user.actions';

import Contact from 'views/Contact';
import Loading from 'components/commons/Loading';

const ContactContainer = (props) => {
  const { contactAction, loading, minimumPurchase,client } = props;

  return (
    !!client ? <Contact
      loading={loading}
      minimumPurchase={minimumPurchase}
      onSubmit={contactAction}
      phone={client.phone}
      email={client.email_info}
      address={client.address}
      mapSrc={`https://maps.google.com/maps?width=700&height=440&hl=en&q=${encodeURIComponent(client.address)}&ie=UTF8&t=&z=16&iwloc=B&output=embed`}

    /> : <Loading/>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  minimumPurchase: state.app.minimumPurchase,
  client: state.app.client,
});

const mapDispatchToProps = (dispatch) => ({
  contactAction: bindActionCreators(contact, dispatch),
});

ContactContainer.propTypes = {
  contactAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
