/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import history from 'helpers/history';
import { productPropTypes } from 'helpers/propTypes';
import { getFullUrl } from 'helpers/window';
import { sendSuccessNotification } from 'helpers/notifications';
import { updateCart } from 'redux/actions/cart.actions';
import { itemsQuantitySelector, itemsSubQuantitySelector } from 'redux/selectors/cart.selector';
import { productImagesSelector } from 'redux/selectors/product.selector';

import Product from 'views/Product';

const ProductContainer = (props) => {
  const {
    images, item, onClose, updateCartAction, cartItemDescriptions, cartItemSubDescriptions,onlyUsers,
    user,
  } = props;
  const singleProductUrl = `/producto/${encodeURIComponent(item.code)}`;
  const singleProductFullUrl = getFullUrl(singleProductUrl);
  const [wholesale, setWholesale] = useState({ id: 0, bool: false, quantity: 0, curva: false });
  const productCart = cartItemDescriptions.find(
    (data) => ((data.wholesale.bool === wholesale.bool) && (data.wholesale.id === wholesale.id)),
  );
  const productCartSub = cartItemSubDescriptions.find(
    (data) => ((data.wholesale.bool === wholesale.bool) && (data.wholesale.id === wholesale.id)),
  );
  const colors = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
  );
  const talles = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 2,
  );

  const combinations = colors?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combination = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combination) a[b.stock_description_id].push(combination);
    return a;
  }, Object.create(null));

  const combinationsTalle = talles?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combinationTalle = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 2)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combinationTalle) a[b.stock_description_id].push(combinationTalle);
    return a;
  }, Object.create(null));

  const [selectedColor, setSelectedColor] = useState(null);
  const [imagesColor, setImagesColor] = useState([]);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    const imagesDescription = [];
    if (color.image_1) {
      imagesDescription.push(color.image_1);
    } else {
      imagesDescription.push(images[0]);
    }
    setImagesColor(imagesDescription);
  };

  const onProductClick = () => {
    onClose();
    history.push(singleProductUrl);
  };

  const onProductCopyClick = () => {
    sendSuccessNotification('Link del producto copiado con éxito');
  };

  const onQuantityChange = (value, description) => {
    if (description) {
      updateCartAction(item.id, value, description.id, wholesale,description.stock_descriptions.name);
    } else {
      updateCartAction(item.id, value, null, wholesale,null);
    }
  };

  const getValidPriceRange = () => {
    const validPrices = talles.filter((talle) => talle.price !== null && talle.price > 0).map((talle) => talle.price);
    const distinctPrices = Array.from(new Set(validPrices));
    if (distinctPrices.length === 1) {
      return `$${validPrices[0].toString()}`;
    }
    const minPrice = Math.min(...distinctPrices);
    const maxPrice = Math.max(...distinctPrices);
    return `$${minPrice} - $${maxPrice}`;
  };
  return (
    <Product
      images={selectedColor ? imagesColor : images}
      selectedColor={selectedColor}
      handleColorSelect={handleColorSelect}
      item={item}
      onProductClick={onProductClick}
      onProductCopyClick={onProductCopyClick}
      onQuantityChange={onQuantityChange}
      productCart={productCart}
      productCartSub={productCartSub}
      cartItemDescriptions={cartItemDescriptions}
      cartItemSubDescriptions={cartItemSubDescriptions}
      singleProductFullUrl={singleProductFullUrl}
      colors={colors}
      wholesale={wholesale}
      setWholesale={setWholesale}
      combinations={combinations}
      talles={talles}
      combinationsTalle={combinationsTalle}
      getValidPriceRange={getValidPriceRange}
      show={onlyUsers == false || (onlyUsers == true && !!user)}
    />
  );
};

const mapStateToProps = (state, props) => ({
  images: productImagesSelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),
  cartItemSubDescriptions: itemsSubQuantitySelector(state, props),
  user: state.user.data,
  onlyUsers: state.app.onlyUsers,
});

const mapDispatchToProps = (dispatch) => ({
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

ProductContainer.defaultProps = {
  cartItemDescriptions: null,
  cartItemSubDescriptions: null,
  onClose: () => { },
};

ProductContainer.propTypes = {
  cartItemDescriptions: PropTypes.arrayOf(PropTypes.shape({
    descriptionId: PropTypes.number,
  })),
  cartItemSubDescriptions: PropTypes.arrayOf(PropTypes.shape({
    descriptionId: PropTypes.number,
  })),
  item: productPropTypes.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
  updateCartAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
