import { get } from 'services/api';

export const getAll = async () => {
  const response = await get('sub_categories');
  return response;
};

export const getSubCategoriesHome = async () => {
  const response = await get('sub_categories/subCategoryHome');
  return response;
};

export default { getAll, getSubCategoriesHome };
