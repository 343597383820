import React from 'react';
import ReactDOM from 'react-dom';
import { initMercadoPago } from '@mercadopago/sdk-react';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { MERCADO_PAGO_PUBLIC_KEY } from 'constants/configs';

import 'index.scss';

initMercadoPago(MERCADO_PAGO_PUBLIC_KEY, { locale: 'es-AR' });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
