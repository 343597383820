import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import store from 'redux/store';
import { APP_INITIALIZE_REQUESTED } from 'redux/constants/app.constants';
import history from 'helpers/history';
import Preloader from 'components/commons/Preloader';
import ScrollToTop from 'containers/ScrollToTop';
import HeaderContainer from 'containers/HeaderContainer';
import NavigationContainer from 'containers/NavigationContainer';
import ModalManager from 'containers/ModalManager';
import Whatsapp from 'components/commons/Whatsapp';
import Footer from 'components/main/Footer';
import 'App.scss';
import 'react-notifications/lib/notifications.css';
import ScriptContainer from 'containers/ScriptContainer';
import Header from 'Header';
// import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  useEffect(() => {
    store.dispatch({ type: APP_INITIALIZE_REQUESTED });
  }, []);

  return (
    <Provider store={store}>
      <ScriptContainer />
      <Router history={history}>
        <div className="super-container">
          <Header/>
          <Preloader />
          <ScrollToTop />
          <NotificationContainer />
          <HeaderContainer />
          <NavigationContainer />
          <ModalManager />
          <Whatsapp />
          <Footer />
        </div>
      </Router>
    </Provider>
  );
};

export default App;
