import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as appConstants from 'redux/constants/app.constants';
import * as constants from 'redux/constants/category.constants';
import {
  getAll, getCategoriesHome, getMarcasHome, getCartelHome,
} from 'services/category.services';

export function* categoryGetAll() {
  try {
    const data = yield call(getAll);
    yield put({ type: constants.CATEGORY_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CATEGORY_GET_ALL_FAILED, error });
  }
}

export function* categoryHomeGet() {
  try {
    const data = yield call(getCategoriesHome);
    yield put({ type: constants.CATEGORY_GET_CATEGORIES_HOME_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CATEGORY_GET_CATEGORIES_HOME_FAILED, error });
  }
}

export function* marcasHomeGet() {
  try {
    const data = yield call(getMarcasHome);
    yield put({ type: constants.CATEGORY_GET_MARCAS_HOME_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CATEGORY_GET_MARCAS_HOME_FAILED, error });
  }
}

export function* cartelHomeGet() {
  try {
    const data = yield call(getCartelHome);
    yield put({ type: constants.CATEGORY_GET_CARTEL_HOME_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CATEGORY_GET_CARTEL_HOME_FAILED, error });
  }
}

export function* watchCategories() {
  yield all([
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, categoryGetAll),
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, categoryHomeGet),
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, marcasHomeGet),
    takeLatest(appConstants.APP_INITIALIZE_REQUESTED, cartelHomeGet),
  ]);
}
