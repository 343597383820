import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from 'components/commons/Button';
import Input from 'components/commons/Input';
import './ResetPasswordEmailForm.scss';

// Validación del formulario
const validate = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Contraseña nueva inválida';
    } else if (values.password.length < 8) {
        errors.password = 'Mínimo 8 caracteres';
    } 
    if (!values.password_confirmation) {
        errors.password_confirmation = 'Contraseña nueva inválida';
    } else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = 'Las contraseñas nuevas no coinciden';
    }
    return errors;
};

const ResetPasswordEmailForm = (props) => {
    const {
        handleSubmit, onSubmit, submitting,
      } = props;
    return (
        <div className="reset-password-container">
            <div className="reset-password-box">
                <h2>Cambiar Contraseña</h2>
                <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
                    <Field
                        component={Input}
                        label="Nueva Contraseña"
                        name="password"
                        type="password"
                        inputClassName="inpute-search"
                    />
                    <Field
                        component={Input}
                        label="Confirmar Contraseña"
                        name="password_confirmation"
                        type="password"
                        inputClassName="inpute-search"
                    />
                    <Button text="Cambiar Contraseña" type="submit" buttonClass="btn-block" disabled={submitting} />
                </form>
            </div>
        </div>
    );
};

ResetPasswordEmailForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

// Decorar el componente del formulario
export default reduxForm({
    form: 'newPassword', // nombre único para el formulario
    validate, // función de validación dada a redux-form
})(ResetPasswordEmailForm);
