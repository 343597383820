import React, { useEffect, useRef } from 'react';

const FileInput = ({ input: { value, onChange, ...inputProps }, meta, label }) => {
  const fileInputRef = useRef();

  useEffect(() => {
    if (fileInputRef.current && value === '') {
      fileInputRef.current.value = ''; // Reset the input file value
    }
  }, [value]);

  const handleChange = (event) => {
    const file = event.target.files[0];
    onChange(file);
  };

  return (
    <div>
      <label>{label}</label>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
        {...inputProps}
      />
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
  );
};

export default FileInput;