import React, { useEffect, useRef, useState } from 'react';
import { jsPDF } from 'jspdf';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import 'jspdf-autotable';
import 'styles/global.scss';

import Prices from 'views/Prices';

const PricesContainer = (props) => {
  const { categories, items, prices } = props;
  const [pdfUrl, setPdfUrl] = useState(null);
  // eslint-disable-next-line new-cap
  const doc = useRef(new jsPDF());

  useEffect(() => {
    const generatePDF = () => {
      if (categories.length && items.length) {
        // Crear un nuevo documento PDF
        doc.current.setProperties({ title: 'ListaDeProductos.pdf' });
        // Agregar texto al documento
        doc.current.text('Productos por Categoría', 10, 10);
        // Obtener productos por categoría y agregarlos al PDF
        const tableWidth = doc.current.internal.pageSize.width;
        let startY = 20;
        categories.forEach((category) => {
          const productsInCategory = items.filter(
            (product) => product.categories.some((categoria) => category.code === categoria.code),
          );
          // const startY = index * 40 + 20; // Posición vertical inicial de la tabla
          if (productsInCategory[0]) {
            const headers = [category.name, 'Nombre', 'Precio']; // Encabezados de la tabla
            const root = document.documentElement;
            const computedStyles = getComputedStyle(root);
            const colorHex = computedStyles.getPropertyValue('--color-base').trim();
            const color = colorHex.replace(/^#/, '');
            // Separar el valor hex en sus componentes (r, g, b)
            const r = parseInt(color.substring(0, 2), 16);
            const g = parseInt(color.substring(2, 4), 16);
            const b = parseInt(color.substring(4, 6), 16);
            // Crear la tabla de productos por categoría
            doc.current.autoTable({
              startY,
              head: [headers],
              body: productsInCategory.map((product) => ['', product.name, product.price]),
              headStyles: { fillColor: [r, g, b] },
              columnStyles: {
                0: { cellWidth: tableWidth * 0.2 },
                1: { cellWidth: tableWidth * 0.4 },
                2: { cellWidth: tableWidth * 0.2 },
              },
            });
            startY = doc.current.lastAutoTable.finalY + 10;
          }
        });
        setPdfUrl(doc.current.output('dataurlstring', { filename: 'ListaDeProductos.pdf' }));
      }
    };

    generatePDF();
  }, [categories, items]);

  const savePDF = () => doc.current.save('ListaDeProductos.pdf');

  return (
    <Prices
      pdfPrecios={pdfUrl}
      prices={prices}
      savePDF={savePDF}
    />
  );
};

const mapStateToProps = (state) => ({
  prices: state.app.prices,
  categories: state.category.items,
  items: state.product.items,
});

PricesContainer.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    })),
  })).isRequired,
  prices: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pdf: PropTypes.string,
  })).isRequired,
};

export default connect(mapStateToProps)(PricesContainer);
