import { getLocalStorage } from 'helpers/storage';

export const getPercentageOrAmount = ({ coupon, subtotal }) => {
  if (!coupon) return 0;
  const { amount, percentage } = coupon;
  return percentage ? (percentage / 100) * subtotal : amount;
};

export const getTotalValueWithoutDiscount = ({ coupon, subtotal }) => {
  const percentageOrAmount = getPercentageOrAmount({ coupon, subtotal });
  return (subtotal - percentageOrAmount) > 0 ? subtotal - percentageOrAmount : 0;
};

export const getTotalValueWithDiscount = ({ coupon, discount, subtotal }) => {
  const percentageOrAmount = getPercentageOrAmount({ coupon, subtotal });
  return (discount - percentageOrAmount) > 0 ? discount - percentageOrAmount : 0;
};

export const getTotalValueWithPercetage = ({ coupon, subtotal }) => {
  const percentageOrAmount = getPercentageOrAmount({ coupon, subtotal });
  return subtotal - percentageOrAmount;
};

export const getTotalAmount = ({ coupon, subtotal }) => {
  const discount = getLocalStorage('discount');
  if (!discount) return getTotalValueWithoutDiscount({ coupon, subtotal });
  return coupon?.percentage
    ? getTotalValueWithPercetage({ coupon, subtotal })
    : getTotalValueWithDiscount({ coupon, discount, subtotal });
};
