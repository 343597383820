import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { productsArrayPropTypes } from 'helpers/propTypes';
import { searchProductsSelector, getSearch } from 'redux/selectors/product.selector';

import Paginator from 'containers/PaginatorContainer';

const SearchProductContainer = (props) => {
  const { items, search } = props;

  return (
    <Paginator
      name="Busqueda"
      products={items}
      url={`/busqueda/${search}`}
      {...props}
    />
  );
};

const mapStateToProps = (state, props) => ({
  items: searchProductsSelector(state, props),
  search: getSearch(state, props),
});

SearchProductContainer.propTypes = {
  items: productsArrayPropTypes.isRequired,
  search: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(SearchProductContainer);
