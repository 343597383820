import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Catalogue from 'views/Catalogue';

const CatalogueContainer = (props) => {
  const {
    catalogs,
  } = props;
  return (
    <Catalogue
      catalogs={catalogs}
    />
  );
};

const mapStateToProps = (state) => ({
  catalogs: state.app.catalogs,
});

CatalogueContainer.propTypes = {
  catalogs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pdf: PropTypes.string,
  })).isRequired,
};

export default connect(mapStateToProps)(CatalogueContainer);
