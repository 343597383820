import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { productsArrayPropTypes } from 'helpers/propTypes';
import PaginatorContainer from 'containers/PaginatorContainer';

const OffersContainer = (props) => {
  const { items } = props;

  return (
    <PaginatorContainer
      name="Ofertas"
      products={items}
      url="/ofertas"
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  items: state.product.offers,
});

OffersContainer.propTypes = {
  items: productsArrayPropTypes.isRequired,
};

export default connect(mapStateToProps)(OffersContainer);
