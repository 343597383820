import React, { useEffect, useState } from 'react';
import { CardPayment } from '@mercadopago/sdk-react';
import PropTypes from 'prop-types';

import Loading from 'components/commons/Loading';

import './SecureCheckoutForm.scss';

const SecureCheckoutForm = (props) => {
  const { email, onSubmit, total } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div className="container secure-checkout-form" data-testid="secure-checkout-form">
      <div className={`secure-checkout-form-loading ${loading ? '' : 'hide'}`}>
        <Loading />
      </div>
      <CardPayment
        initialization={{ amount: total, payer: { email } }}
        onReady={() => setLoading(false)}
        onSubmit={onSubmit}
      />
    </div>
  );
};

SecureCheckoutForm.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default SecureCheckoutForm;
