import React from 'react';
import { connect } from 'react-redux';

import { productsArrayPropTypes } from 'helpers/propTypes';
import PaginatorContainer from 'containers/PaginatorContainer';

const MostSelledContainer = (props) => {
  const { items } = props;

  return (
    <PaginatorContainer
      name="Mas Vendidos"
      products={items}
      url="/mostSelled"
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  items: state.product.mostSelled,
});

MostSelledContainer.propTypes = {
  items: productsArrayPropTypes.isRequired,
};

export default connect(mapStateToProps)(MostSelledContainer);
