import { createSelector } from 'reselect';

import { getProducts } from 'redux/selectors/product.selector';
import { getShippingText } from 'components/forms/PaymentForm/constants';

const getUser = (state) => state.user.data;
const getPurchaseHistory = (state) => state.user.purchaseHistory;

export const userSelector = createSelector([getUser], (user) => user);

export const purchaseHistorySelector = createSelector(
  [getPurchaseHistory, getProducts],
  (purchaseHistory, products) => purchaseHistory.map((purchase) => ({
    orderId: purchase.id,
    date: purchase.date,
    paymentMethod: getShippingText(purchase.payment_method),
    state: purchase.order_state,
    total: purchase.total,
    products: purchase.orders.map((order) => {
      const product = products.find((p) => order.product_id === p.id);
      return {
        id: order.product_id,
        name: order.product_name,
        price: order.price,
        quantity: order.quantity,
        stock: (product?.stock || 0),
        descriptionId: order.description_id,
      };
    }),
  })),
);
