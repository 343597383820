import React, { useEffect, useState } from 'react';
import {
  Col, Nav, Row, Tab,
} from 'react-bootstrap';
import Loading from 'components/commons/Loading';
import PropTypes from 'prop-types';

import downloadImage from './assets/download.svg';
import downloadGreenImage from './assets/downloadGreen.svg';

import './Prices.scss';

const Prices = (props) => {
  const { pdfPrecios, prices, savePDF } = props;
  const [pricesState, setPricesState] = useState([]);
  const [clicked, setClicked] = useState(null);

  useEffect(() => {
    if (prices.length > 0) {
      setClicked(false);
      const initialPricesState = prices.map((price, index) => ({
        ...price,
        clicked: index === 0,
      }));
      setPricesState(initialPricesState);
    } else {
      setClicked(false);
    }
  }, [prices]);

  useEffect(() => {
    if (pdfPrecios) {
      const iframe = document.getElementById('pdfPrecio');
      if (iframe) iframe.src = pdfPrecios;
    }
  }, [pdfPrecios]);

  const handleLinkClick = (index) => {
    const updatedPrices = pricesState.map((price, i) => ({
      ...price,
      clicked: i === index,
    }));
    setPricesState(updatedPrices);
    if (prices.length && index < prices.length) {
      setClicked(false);
    } else {
      setClicked(true);
    }
  };

  return (
    <div className="container fix-space" data-testid="faq-page">
      <div className="row">
        <div className="col clearfix catalogue-container">
          {prices.length > 0
            ? (
              <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      {pricesState.map((price, i) => (
                        <Nav.Item key={`title-${price.id}`}>
                          <Nav.Link eventKey={i} onClick={() => handleLinkClick(i)}>
                            {price.title}
                          </Nav.Link>
                          <a
                            className="download-pdf"
                            download
                            href={price.pdf}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img src={price.clicked ? downloadImage : downloadGreenImage} alt="" />
                          </a>
                        </Nav.Item>
                      ))}
                      <Nav.Item>
                        <Nav.Link
                          eventKey={prices.length}
                          onClick={() => handleLinkClick(prices.length)}
                        >
                          Precios Por Categoria
                        </Nav.Link>
                        {pdfPrecios && (
                          <span className="download-pdf" onClick={savePDF} role="presentation">
                            <img src={clicked ? downloadImage : downloadGreenImage} alt="" />
                          </span>
                        )}
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      {prices.map((price, i) => (
                        <Tab.Pane eventKey={i} key={`pdf-${price.id}`}>
                          <iframe src={price.pdf} width="800" height="600" title={`pdf-precio-${i}`} />
                        </Tab.Pane>
                      ))}
                      <Tab.Pane eventKey={prices.length || 0}>
                        <iframe
                          id="pdfPrecio"
                          width="800"
                          height="600"
                          style={{ display: pdfPrecios ? 'block' : 'none' }}
                          title={`pdf-precio-${prices.length}`}
                        />
                        {!pdfPrecios && <div style={{ height: '600px', width: '800px' }}><Loading /></div>}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            )
            : <h2> No hay precios disponibles</h2>}
        </div>
      </div>
    </div>
  );
};

Prices.defaultProps = {
  pdfPrecios: null,
};

Prices.propTypes = {
  pdfPrecios: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pdf: PropTypes.string,
  })).isRequired,
  savePDF: PropTypes.func.isRequired,
};

export default Prices;
